import { ISettingsGetter } from '@wix/yoshi-flow-editor/tpa-settings';
import settingsParams from '../../components/BookOnline/settingsParams';
import { ServiceListLayoutOptions } from '../../types/types';
import {
  CARD_MAX_WIDTH,
  CARD_MIN_WIDTH,
  GRID_CARD_MIN_WIDTH,
  MOBILE_CARD_MIN_WIDTH,
  SINGLE_CARD_PER_ROW,
} from '../../consts';

export type BodyViewModel = {
  spaceBetweenCards: number;
  maxCardsPerRow: number;
  cardMinWidth: number;
  cardMaxWidth?: number;
  showDivider: boolean;
  dividerWidth: string;
  imageRatio: string;
};

export const createBodyViewModel = ({
  settings,
  scale,
  imageAspectRatio,
  isMobile,
  serviceListLayout,
}: {
  settings: {
    get: ISettingsGetter;
  };
  scale: number;
  imageAspectRatio?: number;
  isMobile: boolean;
  serviceListLayout: ServiceListLayoutOptions;
}): BodyViewModel => {
  const isGridLayout = serviceListLayout === ServiceListLayoutOptions.GRID;
  const isStripLayout = serviceListLayout === ServiceListLayoutOptions.STRIP;

  const spaceBetweenCards = getSpaceBetweenCards(serviceListLayout, settings);

  const maxCardsPerRow = !isGridLayout
    ? SINGLE_CARD_PER_ROW
    : settings.get(settingsParams.cardsPerRow);

  const cardMinWidth = getCardMinWidth(isMobile, isGridLayout, scale);
  const cardMaxWidth = !isMobile && isGridLayout ? CARD_MAX_WIDTH : undefined;

  const showDivider =
    isStripLayout && settings.get(settingsParams.isServicesDividerVisible);

  const dividerWidth = getDividerWidth(
    isStripLayout,
    settings.get(settingsParams.servicesDividerWidth),
  );

  const imageRatio = getImageAspectRatio(isGridLayout, imageAspectRatio);

  return {
    spaceBetweenCards,
    maxCardsPerRow,
    cardMinWidth,
    cardMaxWidth,
    showDivider,
    dividerWidth,
    imageRatio,
  };
};

const getSpaceBetweenCards = (
  serviceListLayout: ServiceListLayoutOptions,
  settings: { get: ISettingsGetter },
) => {
  switch (serviceListLayout) {
    case ServiceListLayoutOptions.GRID:
      return settings.get(settingsParams.gridCardsSpacing);
    case ServiceListLayoutOptions.STRIP:
      return settings.get(settingsParams.stripCardSpacing);
    case ServiceListLayoutOptions.CLASSIC:
    case ServiceListLayoutOptions.OVERLAPPING:
    default:
      return settings.get(settingsParams.cardSpacing);
  }
};

const getCardMinWidth = (
  isMobile: boolean,
  isGridLayout: boolean,
  scale: number,
) => {
  if (isMobile) {
    return MOBILE_CARD_MIN_WIDTH;
  }
  if (isGridLayout) {
    return GRID_CARD_MIN_WIDTH;
  }

  return CARD_MIN_WIDTH * scale;
};

const getImageAspectRatio = (
  isGridLayout: boolean,
  imageAspectRatio?: number,
) => {
  if (imageAspectRatio) {
    return `${Math.round(100 / imageAspectRatio)}%`;
  }

  return `${isGridLayout ? 100 : 0}%`;
};

const getDividerWidth = (
  isStripLayout: boolean,
  servicesDividerWidth: number,
): string => `${isStripLayout ? servicesDividerWidth : 0}px`;
