import { ImageDtoBuilder, WeekDay } from '@wix/bookings-uou-domain';
import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import {
  CatalogServiceDtoBuilder,
  ServicePaymentDtoBuilder,
  ServiceInfoDtoBuilder,
  ServiceScheduleHeaderDtoBuilder,
} from '@wix/bookings-uou-builders';
import {
  CLASSIC_PRESET_ID,
  GRID_PRESET_ID,
  OVERLAPPING_PRESET_ID,
  STRIP_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
  CLASSIC_EDITOR_X_PRESET_ID,
  OVERLAPPING_EDITOR_X_PRESET_ID,
  STRIP_EDITOR_X_PRESET_ID,
  GRID_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
} from '../../../consts';
import {
  DUMMY_CATEGORY_1,
  DUMMY_CATEGORY_2,
  DUMMY_CATEGORY_3,
} from '../dummyCategoriesDto/dummyCategoriesDto';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

interface Map {
  [key: string]: (t: Function) => CatalogServiceDto[];
}

export const createDummyServicesDto = (
  flowAPI: ControllerFlowAPI,
  presetId?: string,
): CatalogServiceDto[] => {
  const {
    translations: { t },
  } = flowAPI;

  const dummyMap: Map = {
    [CLASSIC_PRESET_ID]: createClassicDummyServicesDto,
    [OVERLAPPING_PRESET_ID]: createOverlappingDummyServicesDto,
    [STRIP_PRESET_ID]: createStripDummyServicesDto,
    [GRID_PRESET_ID]: createGridDummyServicesDto,
    [BOOKINGS_MAIN_PAGE_PRESET_ID]: createBookingsMainPageDummyServicesDto,
    [SINGLE_SERVICE_PRESET_ID]: createSingleServiceDummyServicesDto,
    [CLASSIC_EDITOR_X_PRESET_ID]: createEditorXClassicDummyServicesDto,
    [OVERLAPPING_EDITOR_X_PRESET_ID]: createEditorXOverlappingDummyServicesDto,
    [STRIP_EDITOR_X_PRESET_ID]: createEditorXStripDummyServicesDto,
    [GRID_EDITOR_X_PRESET_ID]: createEditorXGridDummyServicesDto,
    [BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID]:
      createEditorXBookingsMainPageDummyServicesDto,
    [SINGLE_SERVICE_EDITOR_X_PRESET_ID]:
      createEditorXSingleServiceDummyServicesDto,
  };

  return dummyMap[presetId as any](t);
};

export const createClassicDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.GROUP)
    .withId('classic-service-1')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(
      new ServicePaymentDtoBuilder()
        .withIsFree(true)
        .withPriceText('dummy.offering-1.classic.price.v1')
        .build(),
    )
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder()
        .withDays([WeekDay.MONDAY, WeekDay.TUESDAY, WeekDay.FRIDAY])
        .withDurationInMinutes(60)
        .build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_0784fee4aebd4b178e31f63f6c121dd6~mv2.jpg')
            .withRelativeUri('11062b_0784fee4aebd4b178e31f63f6c121dd6~mv2.jpg')
            .withWidth(5161)
            .withHeight(3492)
            .build(),
        ])
        .withName(t('dummy.offering-1.classic.name.v1'))
        .withTagLine(t('dummy.offering-1.classic.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService2 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.GROUP)
    .withId('classic-service-2')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(20).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder()
        .withDurationInMinutes(60)
        .withDays([WeekDay.MONDAY, WeekDay.TUESDAY])
        .build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_9fc6d79b5e2b42be9f41346778381dab~mv2.jpg')
            .withRelativeUri('11062b_9fc6d79b5e2b42be9f41346778381dab~mv2.jpg')
            .withWidth(8688)
            .withHeight(5792)
            .build(),
        ])
        .withName(t('dummy.offering-2.classic.name.v1'))
        .withTagLine(t('dummy.offering-2.classic.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService3 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.GROUP)
    .withId('classic-service-3')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(20).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder()
        .withDurationInMinutes(90)
        .withDays([
          WeekDay.SUNDAY,
          WeekDay.MONDAY,
          WeekDay.TUESDAY,
          WeekDay.WEDNESDAY,
          WeekDay.THURSDAY,
          WeekDay.FRIDAY,
          WeekDay.SATURDAY,
        ])
        .build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_0446500bceec497899a6adfb2d5cff9e~mv2.jpg')
            .withRelativeUri('11062b_0446500bceec497899a6adfb2d5cff9e~mv2.jpg')
            .withWidth(5256)
            .withHeight(3504)
            .build(),
        ])
        .withName(t('dummy.offering-3.classic.name.v1'))
        .withTagLine(t('dummy.offering-3.classic.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createEditorXClassicDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('classic-service-1')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(60).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(40).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_0f2218fd616445c7ab09ca78aacdb169~mv2.jpg')
            .withRelativeUri('11062b_0f2218fd616445c7ab09ca78aacdb169~mv2.jpg')
            .withWidth(5630)
            .withHeight(3135)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-1.classic.name'))
        .withTagLine(t('dummy.editor-x.offering-1.classic.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService2 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.GROUP)
    .withId('classic-service-2')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(
      new ServicePaymentDtoBuilder()
        .withPriceText(t('dummy.editor-x.offering-2.classic.price'))
        .withIsFree(true)
        .build(),
    )
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder()
        .withDurationInMinutes(40)
        .withDays([WeekDay.MONDAY, WeekDay.WEDNESDAY])
        .build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_77255a5b3f0247d6b8a5dc7f02ff601f~mv2.jpg')
            .withRelativeUri('11062b_77255a5b3f0247d6b8a5dc7f02ff601f~mv2.jpg')
            .withWidth(6939)
            .withHeight(5411)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-2.classic.name'))
        .withTagLine(t('dummy.editor-x.offering-2.classic.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  dummyServices.push(dummyService1, dummyService2);
  return dummyServices;
};

export const createOverlappingDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('overlapping-service-1')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(50).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(45).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_1edac74605d1465c97cd5de495f4878f~mv2.jpg')
            .withRelativeUri('11062b_1edac74605d1465c97cd5de495f4878f~mv2.jpg')
            .withWidth(4827)
            .withHeight(3620)
            .build(),
        ])
        .withName(t('dummy.offering-1.overlapping.name.v1'))
        .withTagLine(t('dummy.offering-1.overlapping.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService2 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.GROUP)
    .withId('overlapping-service-2')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(70).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder()
        .withDays([WeekDay.MONDAY])
        .withDurationInMinutes(90)
        .build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_2c7d9a449e2f42d08f4ed5ae827e230c~mv2.jpg')
            .withRelativeUri('11062b_2c7d9a449e2f42d08f4ed5ae827e230c~mv2.jpg')
            .withWidth(3247)
            .withHeight(2598)
            .build(),
        ])
        .withName(t('dummy.offering-2.overlapping.name.v1'))
        .withTagLine(t('dummy.offering-2.overlapping.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  dummyServices.push(dummyService1, dummyService2);
  return dummyServices;
};

export const createEditorXOverlappingDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('overlapping-service-1')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(50).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(80).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg')
            .withRelativeUri('11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg')
            .withWidth(3425)
            .withHeight(4153)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-1.overlapping.name'))
        .withTagLine(t('dummy.editor-x.offering-1.overlapping.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService2 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('overlapping-service-2')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(75).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(60).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_bf0adb87a3c34b77b3444b84abd04515~mv2.jpg')
            .withRelativeUri('11062b_bf0adb87a3c34b77b3444b84abd04515~mv2.jpg')
            .withWidth(4480)
            .withHeight(3769)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-2.overlapping.name'))
        .withTagLine(t('dummy.editor-x.offering-2.overlapping.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService3 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('overlapping-service-3')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(50).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(45).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_85d5afe9a7744a7382481773058866c8~mv2.jpg')
            .withRelativeUri('11062b_85d5afe9a7744a7382481773058866c8~mv2.jpg')
            .withWidth(5760)
            .withHeight(6720)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-3.overlapping.name'))
        .withTagLine(t('dummy.editor-x.offering-3.overlapping.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createStripDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('strip-service-1')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(80).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(45).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_54ca9853021049c8abe5ccd1bd63eb4c~mv2.jpg')
            .withRelativeUri('11062b_54ca9853021049c8abe5ccd1bd63eb4c~mv2.jpg')
            .withWidth(5651)
            .withHeight(4129)
            .build(),
        ])
        .withName(t('dummy.offering-1.strip.name.v1'))
        .withTagLine(t('dummy.offering-1.strip.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService2 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('strip-service-2')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(60).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(45).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_1b367614d3cb4bf587f43e2b1c10d5bb~mv2.jpg')
            .withRelativeUri('11062b_1b367614d3cb4bf587f43e2b1c10d5bb~mv2.jpg')
            .withWidth(5651)
            .withHeight(4277)
            .build(),
        ])
        .withName(t('dummy.offering-2.strip.name.v1'))
        .withTagLine(t('dummy.offering-2.strip.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService3 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('strip-service-3')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(35).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(45).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_6d591d82e9e34ede89903b94a4388b7c~mv2.jpg')
            .withRelativeUri('11062b_6d591d82e9e34ede89903b94a4388b7c~mv2.jpg')
            .withWidth(4820)
            .withHeight(3595)
            .build(),
        ])
        .withName(t('dummy.offering-3.strip.name.v1'))
        .withTagLine(t('dummy.offering-3.strip.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createEditorXStripDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('strip-service-1')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(75).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(60).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_7d236aed806f48b6ab70c24c537c5640~mv2.jpg')
            .withRelativeUri('11062b_7d236aed806f48b6ab70c24c537c5640~mv2.jpg')
            .withWidth(5616)
            .withHeight(3744)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-1.strip.name'))
        .withTagLine(t('dummy.editor-x.offering-1.strip.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService2 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('strip-service-2')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(40).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(50).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_a00383fc89cf48b28d60d47be508e082~mv2.jpg')
            .withRelativeUri('11062b_a00383fc89cf48b28d60d47be508e082~mv2.jpg')
            .withWidth(5472)
            .withHeight(3648)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-2.strip.name'))
        .withTagLine(t('dummy.editor-x.offering-2.strip.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService3 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('strip-service-3')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(130).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(90).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_97f61437530d440f9c18def8124e67ec~mv2.jpg')
            .withRelativeUri('11062b_97f61437530d440f9c18def8124e67ec~mv2.jpg')
            .withWidth(4261)
            .withHeight(3196)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-3.strip.name'))
        .withTagLine(t('dummy.editor-x.offering-3.strip.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createGridDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.GROUP)
    .withId('grid-service-1')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(50).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder()
        .withDays([WeekDay.MONDAY, WeekDay.WEDNESDAY])
        .withDurationInMinutes(150)
        .build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_a25a342ff395431f9bcdda5e68648d3e~mv2.jpg')
            .withRelativeUri('11062b_a25a342ff395431f9bcdda5e68648d3e~mv2.jpg')
            .withWidth(6359)
            .withHeight(4042)
            .build(),
        ])
        .withName(t('dummy.offering-1.grid.name.v1'))
        .withTagLine(t('dummy.offering-1.grid.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService2 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.GROUP)
    .withId('grid-service-2')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(35).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder()
        .withDays([WeekDay.FRIDAY])
        .withDurationInMinutes(120)
        .build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_edc564b4d9d745e5943f4ebabedc007a~mv2.jpg')
            .withRelativeUri('11062b_edc564b4d9d745e5943f4ebabedc007a~mv2.jpg')
            .withWidth(3467)
            .withHeight(2311)
            .build(),
        ])
        .withName(t('dummy.offering-2.grid.name.v1'))
        .withTagLine(t('dummy.offering-2.grid.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService3 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.GROUP)
    .withId('grid-service-3')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(40).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder()
        .withDays([WeekDay.TUESDAY])
        .withDurationInMinutes(120)
        .build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_c2d2871f620b43bca4d28cfdaf02c686~mv2.jpg')
            .withRelativeUri('11062b_c2d2871f620b43bca4d28cfdaf02c686~mv2.jpg')
            .withWidth(3467)
            .withHeight(2311)
            .build(),
        ])
        .withName(t('dummy.offering-3.grid.name.v1'))
        .withTagLine(t('dummy.offering-3.grid.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createEditorXGridDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('grid-service-1')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(55).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(30).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_97d1f052abe64d21b75fb9b208f18074~mv2.jpg')
            .withRelativeUri('11062b_97d1f052abe64d21b75fb9b208f18074~mv2.jpg')
            .withWidth(3648)
            .withHeight(3644)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-1.grid.name'))
        .withTagLine(t('dummy.editor-x.offering-1.grid.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService2 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('grid-service-2')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(45).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(120).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_fe1121cd7bf644edbd8e7c125fe4be63~mv2.jpg')
            .withRelativeUri('11062b_fe1121cd7bf644edbd8e7c125fe4be63~mv2.jpg')
            .withWidth(2400)
            .withHeight(2000)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-2.grid.name'))
        .withTagLine(t('dummy.editor-x.offering-2.grid.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  const dummyService3 = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('grid-service-3')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(35).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(30).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_422ec8ffcfeb4ed4b36cc3d5e295ed29~mv2.jpg')
            .withRelativeUri('11062b_422ec8ffcfeb4ed4b36cc3d5e295ed29~mv2.jpg')
            .withWidth(6123)
            .withHeight(8164)
            .build(),
        ])
        .withName(t('dummy.editor-x.offering-3.grid.name'))
        .withTagLine(t('dummy.editor-x.offering-3.grid.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  dummyServices.push(dummyService1, dummyService2, dummyService3);
  return dummyServices;
};

export const createSingleServiceDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyService = new CatalogServiceDtoBuilder()
    .withType(ServiceType.GROUP)
    .withId('single-service-service')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(
      new ServicePaymentDtoBuilder()
        .withIsFree(true)
        .withPriceText(t('dummy.single-service-offering.price.v1'))
        .build(),
    )
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder()
        .withDays([WeekDay.MONDAY, WeekDay.WEDNESDAY])
        .withDurationInMinutes(30)
        .build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_8cfb007420a640ddb5089533123cbdd8~mv2.jpg')
            .withRelativeUri('11062b_8cfb007420a640ddb5089533123cbdd8~mv2.jpg')
            .withWidth(5161)
            .withHeight(3492)
            .build(),
        ])
        .withName(t('dummy.single-service-offering.name.v1'))
        .withTagLine(t('dummy.single-service-offering.tagLine.v1'))
        .build(),
    )
    .withDummyIndication()
    .build();

  return [dummyService];
};

export const createEditorXSingleServiceDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyService = new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId('single-service-service')
    .withCategoryId(DUMMY_CATEGORY_1)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(20).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(60).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([
          new ImageDtoBuilder()
            .withFileName('11062b_241cfd042c4e473483aed978f2e87431~mv2.jpg')
            .withRelativeUri('11062b_241cfd042c4e473483aed978f2e87431~mv2.jpg')
            .withWidth(3631)
            .withHeight(4843)
            .build(),
        ])
        .withName(t('dummy.editor-x.single-service-offering.name'))
        .withTagLine(t('dummy.editor-x.single-service-offering.tagLine'))
        .build(),
    )
    .withDummyIndication()
    .build();

  return [dummyService];
};

export const createBookingsMainPageDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = createDummyServiceForMainPage({
    id: 'main-page-service-1',
    categoryId: DUMMY_CATEGORY_1,
    image: createImageDto(
      '11062b_91fa0735368f425da0bd82073a8c3695~mv2.jpg',
      4527,
      3018,
    ),
    t,
  });
  const dummyService2 = createDummyServiceForMainPage({
    id: 'main-page-service-2',
    categoryId: DUMMY_CATEGORY_1,
    image: createImageDto(
      '11062b_0f11289602d54bafa37c6c028bc2431e~mv2.jpg',
      3666,
      2435,
    ),
    t,
  });
  const dummyService3 = createDummyServiceForMainPage({
    id: 'main-page-service-3',
    categoryId: DUMMY_CATEGORY_1,
    image: createImageDto(
      '11062b_3a7a451a259a41878898a4e53b6b6eac~mv2.jpg',
      2607,
      1738,
    ),
    t,
  });
  const dummyService4 = createDummyServiceForMainPage({
    id: 'main-page-service-4',
    categoryId: DUMMY_CATEGORY_2,
    image: createImageDto(
      '11062b_9d4f78f3e4ec44deafd2d86e5a6d5eb5~mv2.jpg',
      2144,
      1432,
    ),
    t,
  });
  const dummyService5 = createDummyServiceForMainPage({
    id: 'main-page-service-5',
    categoryId: DUMMY_CATEGORY_2,
    image: createImageDto(
      '11062b_0d0ac3c3b9f5480d8a8636fe70e7d5b2~mv2.jpg',
      1761,
      1175,
    ),
    t,
  });
  const dummyService6 = createDummyServiceForMainPage({
    id: 'main-page-service-6',
    categoryId: DUMMY_CATEGORY_2,
    image: createImageDto(
      '11062b_8feb05dc20724cd0ae12466e62b51872~mv2.jpg',
      1984,
      1324,
    ),
    t,
  });
  const dummyService7 = createDummyServiceForMainPage({
    id: 'main-page-service-7',
    categoryId: DUMMY_CATEGORY_3,
    image: createImageDto(
      '11062b_7be34dd7781a419892b217eae1188612~mv2.jpg',
      1592,
      1061,
    ),
    t,
  });
  const dummyService8 = createDummyServiceForMainPage({
    id: 'main-page-service-8',
    categoryId: DUMMY_CATEGORY_3,
    image: createImageDto(
      '11062b_64186737b5f44cb683764b92c4bb7791~mv2.jpg',
      4865,
      3247,
    ),
    t,
  });
  const dummyService9 = createDummyServiceForMainPage({
    id: 'main-page-service-9',
    categoryId: DUMMY_CATEGORY_3,
    image: createImageDto(
      '11062b_c5819ae1c5684aaa898164ccde6e970c~mv2.jpg',
      2401,
      1500,
    ),
    t,
  });

  dummyServices.push(
    dummyService1,
    dummyService2,
    dummyService3,
    dummyService4,
    dummyService5,
    dummyService6,
    dummyService7,
    dummyService8,
    dummyService9,
  );
  return dummyServices;
};

export const createEditorXBookingsMainPageDummyServicesDto = (
  t: Function,
): CatalogServiceDto[] => {
  const dummyServices: CatalogServiceDto[] = [];

  const dummyService1 = createDummyServiceForMainPage({
    categoryId: DUMMY_CATEGORY_1,
    image: createImageDto(
      '11062b_77255a5b3f0247d6b8a5dc7f02ff601f~mv2.jpg',
      6939,
      5411,
    ),
    price: 45,
    t,
  });
  const dummyService2 = createDummyServiceForMainPage({
    categoryId: DUMMY_CATEGORY_1,
    image: createImageDto(
      '11062b_0f2218fd616445c7ab09ca78aacdb169~mv2.jpg',
      5630,
      3135,
    ),
    price: 60,
    t,
  });
  const dummyService3 = createDummyServiceForMainPage({
    categoryId: DUMMY_CATEGORY_1,
    image: createImageDto(
      '11062b_871ca2ea5a6349bfab4edf5e6f72045c~mv2.jpg',
      3987,
      4899,
    ),
    price: 50,
    t,
  });
  const dummyService4 = createDummyServiceForMainPage({
    categoryId: DUMMY_CATEGORY_2,
    image: createImageDto(
      '11062b_97d1f052abe64d21b75fb9b208f18074~mv2.jpg',
      3648,
      3644,
    ),
    t,
  });
  const dummyService5 = createDummyServiceForMainPage({
    categoryId: DUMMY_CATEGORY_2,
    image: createImageDto(
      '11062b_fe1121cd7bf644edbd8e7c125fe4be63~mv2.jpg',
      2400,
      2000,
    ),
    t,
  });
  const dummyService6 = createDummyServiceForMainPage({
    categoryId: DUMMY_CATEGORY_2,
    image: createImageDto(
      '11062b_422ec8ffcfeb4ed4b36cc3d5e295ed29~mv2.jpg',
      6123,
      8164,
    ),
    t,
  });
  const dummyService7 = createDummyServiceForMainPage({
    categoryId: DUMMY_CATEGORY_3,
    image: createImageDto(
      '11062b_85d5afe9a7744a7382481773058866c8~mv2.jpg',
      5760,
      6720,
    ),
    price: 50,
    t,
  });
  const dummyService8 = createDummyServiceForMainPage({
    categoryId: DUMMY_CATEGORY_3,
    image: createImageDto(
      '11062b_bf0adb87a3c34b77b3444b84abd04515~mv2.jpg',
      4480,
      3769,
    ),
    price: 75,
    t,
  });
  const dummyService9 = createDummyServiceForMainPage({
    categoryId: DUMMY_CATEGORY_3,
    image: createImageDto(
      '11062b_ee4c6f7007fb49b5ab00657a5be9471a~mv2.jpg',
      3425,
      4153,
    ),
    price: 50,
    t,
  });

  dummyServices.push(
    dummyService1,
    dummyService2,
    dummyService3,
    dummyService4,
    dummyService5,
    dummyService6,
    dummyService7,
    dummyService8,
    dummyService9,
  );

  return dummyServices;
};

const createDummyServiceForMainPage = ({
  id,
  categoryId,
  image,
  price = 45,
  t,
}: {
  id?: string;
  categoryId: string;
  image: any;
  price?: number;
  t: Function;
}): CatalogServiceDto => {
  return new CatalogServiceDtoBuilder()
    .withType(ServiceType.INDIVIDUAL)
    .withId(id || 'main-page-service')
    .withCategoryId(categoryId)
    .withPayment(new ServicePaymentDtoBuilder().withPrice(price).build())
    .withScheduleHeader(
      new ServiceScheduleHeaderDtoBuilder().withDurationInMinutes(60).build(),
    )
    .withInfo(
      new ServiceInfoDtoBuilder()
        .withImages([image])
        .withName(t('dummy.offering.bookings.main.page.name'))
        .withTagLine('')
        .build(),
    )
    .withDummyIndication()
    .build();
};

const createImageDto = (url: string, width: number, height: number) => {
  return new ImageDtoBuilder()
    .withFileName(url)
    .withRelativeUri(url)
    .withHeight(height)
    .withWidth(width)
    .build();
};
