import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import {
  CatalogServiceDto,
  ServiceType,
  OfferedAsType,
  PaymentType,
  ActiveFeatures,
} from '@wix/bookings-uou-types';
import { ServiceIntent } from '../navigation/navigation.const';
import { isFeatureEnabled } from '@wix/bookings-config/dist/src/active-features/feature-enabler';
import { hasOnlinePaymentMethods } from '../../api/hasOnlinePayments';

export const enum pricingPlanConst {
  PAGE_NOT_INSTALLED = 'PageNotInstalled',
  NO_PLANS_ASSIGNED_TO_OFFERING = 'NoPlansAssignedToOffering',
}

export interface ShouldNavigateResponse {
  canBook: boolean;
  reason?: ShouldNavigateReason;
}

export interface ShouldNavigateReason {
  premiumError: boolean;
  pricingPlanError: boolean;
  isServiceConnectedToPricingPlans: boolean;
  isPricingPlanInstalled: boolean;
}

export type CanBookResult = {
  canBook: boolean;
  reason: ShouldNavigateReason;
};

export class BookingValidations {
  constructor(private readonly wixSdkAdapter: WixOOISDKAdapter) {}

  async shouldNavigate(
    service: CatalogServiceDto,
    activeFeatures: ActiveFeatures,
    intent: ServiceIntent,
    isDayful: boolean = false,
  ): Promise<ShouldNavigateResponse> {
    if (
      intent === ServiceIntent.SHOW_DETAILS ||
      this.wixSdkAdapter.isPreviewMode() ||
      this.wixSdkAdapter.isDemoMode() ||
      this.wixSdkAdapter.isTemplateMode()
    ) {
      return { canBook: true };
    }
    return this.canBook(service, activeFeatures, isDayful);
  }

  canBook(
    service: CatalogServiceDto,
    activeFeatures: ActiveFeatures,
    isDayful: boolean = false,
  ): Promise<CanBookResult> {
    return this.wixSdkAdapter
      .isPricingPlanInstalled()
      .then(async (isPricingPlanInstalled) => {
        const couldBePremium = await this.couldBePremiumNavigate(
          service,
          activeFeatures,
          isDayful,
        );
        const couldBePricingPlan = couldBePricingPlanNavigate(
          service,
          isPricingPlanInstalled,
        );
        return {
          canBook: couldBePremium && couldBePricingPlan.canBook,
          reason: {
            premiumError: !couldBePremium,
            pricingPlanError: !couldBePricingPlan.canBook,
            isServiceConnectedToPricingPlans:
              couldBePricingPlan.isServiceConnectedToPricingPlans,
            isPricingPlanInstalled,
          },
        };
      });
  }

  private async couldBePremiumNavigate(
    service: CatalogServiceDto,
    activeFeatures: ActiveFeatures,
    isDayful: boolean = false,
  ): Promise<boolean> {
    return (
      service.type === ServiceType.COURSE ||
      (isFeatureEnabled(activeFeatures, service.type) &&
        this.applicableForPayments(service, isDayful))
    );
  }

  private applicableForPayments(
    service: CatalogServiceDto,
    isDayful: boolean = false,
  ) {
    if (isDayful && service.payment.paymentType === PaymentType.ONLINE) {
      const accountId = `${this.wixSdkAdapter.getAppDefId()}:${this.wixSdkAdapter.getInstanceId()}`;
      return hasOnlinePaymentMethods({ accountId });
    }
    return true;
  }
}

function couldBePricingPlanNavigate(
  service: CatalogServiceDto,
  isPricingPlanInstalled: boolean,
): {
  canBook: boolean;
  isServiceConnectedToPricingPlans: boolean;
} {
  const isServiceConnectedToPricingPlans =
    isServiceConnectedToPricingPlan(service);

  const serviceOfferedAsPricingPlan = isServiceOfferedAsPricingPlan(
    service,
    isPricingPlanInstalled,
  );
  return {
    canBook:
      !serviceOfferedAsPricingPlan ||
      (isServiceConnectedToPricingPlans && isPricingPlanInstalled),
    isServiceConnectedToPricingPlans,
  };
}

const isServiceConnectedToPricingPlan = (service: CatalogServiceDto) => {
  return !!(
    service.pricingPlanInfo &&
    service.pricingPlanInfo.pricingPlans &&
    service.pricingPlanInfo.pricingPlans.length
  );
};

const isServiceOfferedAsPricingPlan = (
  offeringViewModel: CatalogServiceDto,
  isPricingPlanInstalled: boolean,
) =>
  getOfferedAs(offeringViewModel, isPricingPlanInstalled).indexOf(
    OfferedAsType.PRICING_PLAN,
  ) > -1;

const getOfferedAs = (
  service: CatalogServiceDto,
  isPricingPlanInstalled: boolean,
): OfferedAsType[] => {
  if (
    service.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0 &&
    service.offeredAs.indexOf(OfferedAsType.PRICING_PLAN) >= 0
  ) {
    if (service.pricingPlanInfo.pricingPlans.length === 0) {
      return [OfferedAsType.ONE_TIME];
    }
    if (!isPricingPlanInstalled) {
      return [OfferedAsType.ONE_TIME];
    }
  }
  return service.offeredAs;
};

export const getNotifyPricingPlanRequest = (
  service: CatalogServiceDto,
  reason: ShouldNavigateReason,
) => {
  const reasons = [];
  if (!reason.isPricingPlanInstalled) {
    reasons.push(pricingPlanConst.PAGE_NOT_INSTALLED);
  }
  if (!reason.isServiceConnectedToPricingPlans) {
    reasons.push(pricingPlanConst.NO_PLANS_ASSIGNED_TO_OFFERING);
  }
  const offeringId = service.id;
  return { reasons, offeringId };
};
