import { ServiceType } from '@wix/bookings-uou-types';

export const serviceListReferralInfo = 'service_list_widget';
export const WidgetNamePhase1 = 'widget-phase-one';

export const BiServiceType = {
  [ServiceType.INDIVIDUAL]: 'ind',
  [ServiceType.GROUP]: 'class',
  [ServiceType.COURSE]: 'COURSE',
};

export enum ReferralInfo {
  WIDGET = 'widget',
  SERVICE_IMAGE = 'widget_image',
  SERVICE_TITLE = 'widget_title',
  BOOK_BUTTON = 'widget_button',
  MORE_INFO_BUTTON = 'widget_more_info_label',
}

export const ActionNames = {
  NAVIGATE_TO_CALENDAR: 'navigate_to_calendar',
};
