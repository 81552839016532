import { FormFactor } from './types';
import * as _ from 'lodash';

export function cleanNulls(object: any) {
  object &&
    Object.keys(object).forEach(
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      (key) => object[key] === null && delete object[key],
    );
  return object;
}

// @ts-expect-error
export const isMobileFromFormFactor = (props) =>
  _.get(props, 'host.formFactor', FormFactor.DESKTOP) === FormFactor.MOBILE;

export const getSiteTextPresets = () =>
  new Promise((resolve) => {
    window.Wix.Styles.getSiteTextPresets(resolve);
  });

export const getSiteColors = () =>
  new Promise((resolve) => {
    window.Wix.Styles.getSiteColors(resolve);
  });

export const getStyleParams = () =>
  new Promise((resolve) => {
    window.Wix.Styles.getStyleParams(resolve);
  });

export const getCurrentStyles = () =>
  Promise.all([getSiteColors(), getSiteTextPresets(), getStyleParams()]);

export const mergeOpacityToColor = (
  sourceColor: string,
  destinationColor: string,
): string => {
  if (sourceColor.startsWith('rgba') && destinationColor.startsWith('rgb')) {
    if (destinationColor.startsWith('rgba')) {
      const splitDestinationColorToRGB = destinationColor
        .substring(5, destinationColor.length - 1)
        .split(',');
      const splitSourceColorToRGB = sourceColor
        .substring(5, sourceColor.length - 1)
        .split(',');
      splitDestinationColorToRGB.pop();
      splitDestinationColorToRGB.push(splitSourceColorToRGB.pop() as string);
      return `rgba(${splitDestinationColorToRGB.join(',')})`;
    } else {
      const splitDestinationColorToRGB = destinationColor
        .substring(4, destinationColor.length - 1)
        .split(',');
      const splitSourceColorToRGB = sourceColor
        .substring(5, sourceColor.length - 1)
        .split(',');
      splitDestinationColorToRGB.push(splitSourceColorToRGB.pop() as string);
      return `rgba(${splitDestinationColorToRGB.join(',')})`;
    }
  }
  return destinationColor;
};
