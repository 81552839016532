import { ServiceListSettings } from './appSettings';
import {
  AlignmentOptions,
  ButtonStyleOptions,
  DisplayServicesByOptions,
  DockLocationOptions,
  FilterLayoutOptions,
  FilterServicesByOptions,
  ImagePositionOptions,
  ImageResizeOptions,
  ImageShapeOptions,
  ImageSizeOptions,
  ServiceListLayoutOptions,
} from '../../src/types/types';
import { cleanNulls } from '../utils';
import { SelectedResources } from '../types';

export function mergeAppSettingsCustomsToSettingsParams(
  appSettings: ServiceListSettings,
): {
  selectedItems: SelectedResources;
  selectedLocations: string[];
  bookButtonStyle: ButtonStyleOptions;
  'bookButtonStyle▶︎m': ButtonStyleOptions;
  filterLayout: FilterLayoutOptions;
  'filterLayout▶︎m': FilterLayoutOptions;
  filterServicesBy: FilterServicesByOptions;
  displayServicesBy: DisplayServicesByOptions;
  listFilterAlignment: AlignmentOptions;
  textAlignment: AlignmentOptions;
  titleAlignment: AlignmentOptions;
  serviceListLayout: ServiceListLayoutOptions;
  'serviceListLayout▶︎m': ServiceListLayoutOptions;
  serviceImagePosition: ImagePositionOptions;
  serviceImageShape: ImageShapeOptions;
  'serviceImageShape▶︎m': ImageShapeOptions;
  serviceImageResize: ImageResizeOptions;
  serviceImageDockLocation: DockLocationOptions;
  'textAlignment▶︎m': AlignmentOptions;
  'serviceImageResize▶︎m': ImageResizeOptions;
  'serviceImageDockLocation▶︎m': DockLocationOptions;
} {
  const getAppSettingsValue = <T extends unknown>(
    appSettingsValue: T,
  ): T | null => {
    return typeof appSettingsValue !== 'undefined' ? appSettingsValue : null;
  };
  const appSettingsCustom: {
    selectedItems: SelectedResources | null;
    selectedLocations: string[] | null;
    bookButtonStyle: ButtonStyleOptions | null;
    'bookButtonStyle▶︎m': ButtonStyleOptions | null;
    filterLayout: FilterLayoutOptions | null;
    'filterLayout▶︎m': FilterLayoutOptions | null;
    filterServicesBy: FilterServicesByOptions | null;
    displayServicesBy: DisplayServicesByOptions | null;
    listFilterAlignment: AlignmentOptions | null;
    textAlignment: AlignmentOptions | null;
    titleAlignment: AlignmentOptions | null;
    serviceListLayout: ServiceListLayoutOptions | null;
    'serviceListLayout▶︎m': ServiceListLayoutOptions | null;
    serviceImagePosition: ImagePositionOptions | null;
    serviceImageShape: ImageShapeOptions | null;
    'serviceImageShape▶︎m': ImageShapeOptions | null;
    serviceImageResize: ImageResizeOptions | null;
    serviceImageDockLocation: DockLocationOptions | null;
    'textAlignment▶︎m': AlignmentOptions | null;
    'serviceImageResize▶︎m': ImageResizeOptions | null;
    serviceImageSize: ImageSizeOptions | null;
    'serviceImageSize▶︎m': ImageSizeOptions | null;
    'serviceImageDockLocation▶︎m': DockLocationOptions | null;
  } = {
    bookButtonStyle: getAppSettingsValue<ButtonStyleOptions>(
      appSettings.BUTTON_STYLE as ButtonStyleOptions,
    ),
    'bookButtonStyle▶︎m': getAppSettingsValue<ButtonStyleOptions>(
      appSettings.MOBILE_BUTTON_STYLE as ButtonStyleOptions,
    ),
    filterLayout: getAppSettingsValue<FilterLayoutOptions>(
      appSettings.CATEGORY_LAYOUT_OPTION as FilterLayoutOptions,
    ),
    'filterLayout▶︎m': getAppSettingsValue<FilterLayoutOptions>(
      appSettings.MOBILE_CATEGORY_LAYOUT_OPTION as FilterLayoutOptions,
    ),
    filterServicesBy: getAppSettingsValue<FilterServicesByOptions>(
      appSettings.CATEGORIES_TYPE as FilterServicesByOptions,
    ),
    displayServicesBy: getAppSettingsValue<DisplayServicesByOptions>(
      appSettings.FILTER_BY as DisplayServicesByOptions,
    ),
    listFilterAlignment: getAppSettingsValue<AlignmentOptions>(
      appSettings.CATEGORY_ALIGNMENT as AlignmentOptions,
    ),
    textAlignment: getAppSettingsValue<AlignmentOptions>(
      appSettings.TEXT_ALIGNMENT as AlignmentOptions,
    ),
    'textAlignment▶︎m': getAppSettingsValue<AlignmentOptions>(
      appSettings.MOBILE_TEXT_ALIGNMENT as AlignmentOptions,
    ),
    titleAlignment: getAppSettingsValue<AlignmentOptions>(
      appSettings.MULTI_OFFERINGS_TITLE_ALIGNMENT as AlignmentOptions,
    ),
    serviceListLayout: getAppSettingsValue<ServiceListLayoutOptions>(
      appSettings.OFFERINGS_LIST_LAYOUT as ServiceListLayoutOptions,
    ),
    'serviceListLayout▶︎m': getAppSettingsValue<ServiceListLayoutOptions>(
      appSettings.MOBILE_OFFERING_LIST_LAYOUT as ServiceListLayoutOptions,
    ),
    serviceImagePosition: getAppSettingsValue<ImagePositionOptions>(
      appSettings.IMAGE_POSITION_OPTION as ImagePositionOptions,
    ),
    serviceImageShape: getAppSettingsValue<ImageShapeOptions>(
      appSettings.IMAGE_SHAPE_OPTION as ImageShapeOptions,
    ),
    'serviceImageShape▶︎m': getAppSettingsValue<ImageShapeOptions>(
      appSettings.MOBILE_IMAGE_SHAPE_OPTION as ImageShapeOptions,
    ),
    serviceImageResize: getAppSettingsValue<ImageResizeOptions>(
      appSettings.IMAGE_RESIZE_OPTION as ImageResizeOptions,
    ),
    'serviceImageResize▶︎m': getAppSettingsValue<ImageResizeOptions>(
      appSettings.MOBILE_IMAGE_RESIZE_OPTION as ImageResizeOptions,
    ),
    serviceImageSize: getAppSettingsValue<ImageSizeOptions>(
      appSettings.IMAGE_SIZE_OPTION as ImageSizeOptions,
    ),
    'serviceImageSize▶︎m': getAppSettingsValue<ImageSizeOptions>(
      appSettings.MOBILE_IMAGE_SIZE_OPTION as ImageSizeOptions,
    ),
    serviceImageDockLocation: getAppSettingsValue<DockLocationOptions>(
      appSettings.PIN_LOCATION as DockLocationOptions,
    ),
    'serviceImageDockLocation▶︎m': getAppSettingsValue<DockLocationOptions>(
      appSettings.MOBILE_PIN_LOCATION as DockLocationOptions,
    ),
    selectedItems: getAppSettingsValue<SelectedResources>(
      appSettings.SELECTED_RESOURCES as SelectedResources,
    ),
    selectedLocations: getAppSettingsValue<string[]>(
      appSettings.SELECTED_LOCATIONS as string[],
    ),
  };

  return cleanNulls(appSettingsCustom);
}
