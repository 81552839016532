import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
} from '../../../consts';
import {
  OfferingCategoryDto,
  OfferingCategoryDtoBuilder,
} from '@wix/bookings-uou-domain';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const DUMMY_CATEGORY_1 = 'dummy-category-id-1';
export const DUMMY_CATEGORY_2 = 'dummy-category-id-2';
export const DUMMY_CATEGORY_3 = 'dummy-category-id-3';

export const createDummyCategoriesDto = (
  flowAPI: ControllerFlowAPI,
  presetId: string,
): OfferingCategoryDto[] => {
  const {
    translations: { t },
  } = flowAPI;
  if (
    presetId === BOOKINGS_MAIN_PAGE_PRESET_ID ||
    presetId === BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID
  ) {
    return [
      new OfferingCategoryDtoBuilder()
        .withCategoryId(DUMMY_CATEGORY_1)
        .withName(t('dummy.category-1.bookings.main.page.name'))
        .withOrder(0)
        .build(),
      new OfferingCategoryDtoBuilder()
        .withCategoryId(DUMMY_CATEGORY_2)
        .withName(t('dummy.category-2.bookings.main.page.name'))
        .withOrder(1)
        .build(),
      new OfferingCategoryDtoBuilder()
        .withCategoryId(DUMMY_CATEGORY_3)
        .withName(t('dummy.category-3.bookings.main.page.name'))
        .withOrder(1)
        .build(),
    ];
  }
  return [
    new OfferingCategoryDtoBuilder()
      .withCategoryId(DUMMY_CATEGORY_1)
      .withName(t('dummy.category-1.bookings.main.page.name'))
      .withOrder(0)
      .build(),
  ];
};
