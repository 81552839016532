import { DataCapsule, FrameStorageStrategy } from 'data-capsule';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { ServiceIntent } from './navigation.const';
import {
  BOOKINGS_CHECKOUT_SECTION_ID,
  BOOKINGS_SCHEDULER_SECTION_ID,
} from '@wix/bookings-uou-domain';
import { CatalogServiceDto, ServiceType } from '@wix/bookings-uou-types';
import { BOOKINGS_SERVICE_PAGE_SECTION_ID } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/bookings.const';
import { serviceListReferralInfo } from '../bi/consts';

export enum WidgetDeepLinkConst {
  REFRESH_APP = 'widgetRefreshApp',
}

export enum StorageConst {
  DEEP_LINK = 'deepLink',
}

export enum ClientConst {
  BOOKINGS_PAGE_ID = 'bookingsPageId',
}

export class Navigation {
  constructor(
    private readonly wixSdkAdapter: WixOOISDKAdapter,
    private readonly compId: string,
  ) {}

  async navigateToApp({
    service,
    intent,
    staff,
    location,
  }: {
    service: CatalogServiceDto;
    intent: ServiceIntent;
    staff?: string;
    location?: string;
  }): Promise<void> {
    if (this.wixSdkAdapter.isRunningInIframe()) {
      return this.legacyNavigateToApp(service, intent);
    }
    return this.ooiNavigateToApp(service, intent, {
      staff,
      location,
    });
  }

  private async ooiNavigateToApp(
    service: CatalogServiceDto,
    intent: ServiceIntent,
    { staff, location }: { staff?: string; location?: string },
  ) {
    const optionalParams = {
      referral: serviceListReferralInfo,
      ...(staff ? { staff } : {}),
      ...(location ? { location } : {}),
    };
    if (this.shouldNavigateToCalendar(service, intent)) {
      return this.wixSdkAdapter.navigateToBookingsCalendarPage(
        service.urlName,
        optionalParams,
      );
    }

    return this.wixSdkAdapter.navigateToBookingsServicePage(
      service.dummy ? '' : service.urlName,
      optionalParams,
    );
  }

  private shouldNavigateToCalendar(
    service: CatalogServiceDto,
    intent: ServiceIntent,
  ) {
    return (
      intent === ServiceIntent.BOOK_OFFERING &&
      !service.dummy &&
      service.type !== ServiceType.COURSE
    );
  }

  private async legacyNavigateToApp(
    service: CatalogServiceDto,
    intent: ServiceIntent,
  ) {
    const isServicePageInstalled =
      await this.wixSdkAdapter.isServicePageInstalled();
    const isBookCheckoutInstalled =
      await this.wixSdkAdapter.isBookCheckoutInstalled();
    const capsule = await this.legacyStoreDataCapsule(service, intent);
    let sectionId = BOOKINGS_SCHEDULER_SECTION_ID;
    if (isServicePageInstalled && intent === ServiceIntent.SHOW_DETAILS) {
      sectionId = BOOKINGS_SERVICE_PAGE_SECTION_ID;
    } else if (isBookCheckoutInstalled) {
      sectionId = BOOKINGS_CHECKOUT_SECTION_ID;
    }
    const onNavigationBySectionIdFailed = () => {
      window.Wix.Utils.navigateToSection(
        { sectionId, state: '', queryParams: {} },
        () => {},
      );
    };
    window.Wix.Utils.navigateToSection(
      {
        sectionId,
        state: '',
        queryParams: {},
      },
      onNavigationBySectionIdFailed,
    );

    return this.shouldRefreshBookings(capsule).then((shouldRefresh) => {
      if (shouldRefresh) {
        window.Wix.PubSub.publish(WidgetDeepLinkConst.REFRESH_APP, '', true);
      }
    });
  }

  async legacyStoreDataCapsule(
    service: CatalogServiceDto,
    intent: ServiceIntent,
  ) {
    const capsule = new DataCapsule({
      strategy: new FrameStorageStrategy(window.top, '*', this.compId, {}),
      namespace: 'wix',
    });
    if (!service.dummy) {
      const data = {
        serviceId: service.id,
        intent,
        timestamp: new Date().getTime(),
      };
      await capsule.setItem(StorageConst.DEEP_LINK, data);
    }
    return capsule;
  }

  private shouldRefreshBookings(capsule: DataCapsule) {
    return new Promise((resolve) => {
      capsule
        .getItem(ClientConst.BOOKINGS_PAGE_ID)
        .then((bookingsPageId) => {
          window.Wix.getCurrentPageId((currPageId: string) =>
            resolve(bookingsPageId === currPageId),
          );
        })
        .catch(() => resolve(false));
    });
  }
}
