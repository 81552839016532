import { ServiceListSettings } from './appSettings';
import { ISettingsParamsBooleansKeys } from '../../src/components/BookOnline/settingsParams';
import { MobileSettingsKeys, SettingsKeys } from '../types';
import { cleanNulls } from '../utils';

const settingsParamsBooleansKeyToAppSettingsMapper: {
  [key in ISettingsParamsBooleansKeys]: SettingsKeys | MobileSettingsKeys;
} = {
  flipImageAndTextRatio: SettingsKeys.IMAGE_AND_TEXT_RATIO_IS_FLIPED,
  isServiceImageVisible: SettingsKeys.DISPLAY_IMAGE,
  [`${ISettingsParamsBooleansKeys.isServiceImageVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_IMAGE,
  isListFilterStretched: SettingsKeys.FIT_CATEGORY_WIDTH,
  isServicesDividerVisible: SettingsKeys.DISPLAY_SERVICE_DIVIDER,
  isOnlineBadgeVisible: SettingsKeys.DISPLAY_ONLINE_INDICATION,
  [`${ISettingsParamsBooleansKeys.isOnlineBadgeVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_ONLINE_INDICATION,
  isTagLineVisible: SettingsKeys.DISPLAY_TAG_LINE,
  [`${ISettingsParamsBooleansKeys.isTagLineVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_TAG_LINE,
  isMoreInfoButtonVisible: SettingsKeys.DISPLAY_MORE_INFO_LABEL,
  [`${ISettingsParamsBooleansKeys.isMoreInfoButtonVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_MORE_INFO_LABEL,
  isServiceDividerVisible: SettingsKeys.DISPLAY_DIVIDER,
  [`${ISettingsParamsBooleansKeys.isServiceDividerVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_DIVIDER,
  isServiceOfferedDaysVisible: SettingsKeys.DISPLAY_DAYS_OFFERED,
  [`${ISettingsParamsBooleansKeys.isServiceOfferedDaysVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_DAYS_OFFERED,
  isServiceStartDateVisible: SettingsKeys.DISPLAY_START_DATE,
  [`${ISettingsParamsBooleansKeys.isServiceStartDateVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_START_DATE,
  isCourseAvailabilityVisible: SettingsKeys.DISPLAY_COURSE_AVAILABILITY,
  [`${ISettingsParamsBooleansKeys.isCourseAvailabilityVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_COURSE_AVAILABILITY,
  displayNumberOfSpots: SettingsKeys.DISPLAY_NUMBER_OF_SPOTS,
  [`${ISettingsParamsBooleansKeys.displayNumberOfSpots}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_NUMBER_OF_SPOTS,
  isServiceDurationVisible: SettingsKeys.DISPLAY_DURATION,
  [`${ISettingsParamsBooleansKeys.isServiceDurationVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_DURATION,
  isServicePriceVisible: SettingsKeys.DISPLAY_PRICE,
  [`${ISettingsParamsBooleansKeys.isServicePriceVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_PRICE,
  isBookButtonVisible: SettingsKeys.DISPLAY_BUTTON,
  [`${ISettingsParamsBooleansKeys.isBookButtonVisible}▶︎m`]:
    MobileSettingsKeys.MOBILE_DISPLAY_BUTTON,
  showAllServicesFilterOption: SettingsKeys.CATEGORY_ALL_SERVICES_SHOW,
  isTitleVisible: SettingsKeys.DISPLAY_MULTI_OFFERINGS_TITLE,
  isListFilterVisible: SettingsKeys.DISPLAY_CATEGORIES,
};

export function mergeAppSettingsBooleansToSettingsParams(
  appSettings: ServiceListSettings,
): { [p: string]: boolean } {
  const appSettingsBooleans: {
    [key in ISettingsParamsBooleansKeys]?: boolean;
  } = {};

  (
    Object.keys(
      settingsParamsBooleansKeyToAppSettingsMapper,
    ) as ISettingsParamsBooleansKeys[]
  ).forEach((settingsParamBooleanKey) => {
    const appSettingsValue =
      appSettings[
        settingsParamsBooleansKeyToAppSettingsMapper[settingsParamBooleanKey]
      ];
    if (typeof appSettingsValue !== 'undefined') {
      appSettingsBooleans[settingsParamBooleanKey] =
        appSettingsValue as boolean;
    }
  });

  return cleanNulls(appSettingsBooleans);
}
