import { WidgetViewModel } from '../../viewModel/viewModel';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const LOST_BUSINESS_NOTIFIER_NOTIFY_PATH =
  '/_serverless/wixstores-lost-business-notifier/lost-business';
export type OnDialogCloseAction = () => void;

export const createOnDialogCloseAction = ({
  widgetViewModel,
  setProps,
  isAnywhereFlow = false,
  flowAPI,
}: {
  widgetViewModel: WidgetViewModel;
  setProps: Function;
  isAnywhereFlow?: boolean;
  flowAPI?: ControllerFlowAPI;
}): OnDialogCloseAction => {
  return () => {
    widgetViewModel.showDialog = false;
    if (isAnywhereFlow && flowAPI) {
      flowAPI.experiments.enabled('specs.bookings.BookingsAnywhereUoUEmail') &&
        flowAPI.httpClient
          .post(LOST_BUSINESS_NOTIFIER_NOTIFY_PATH)
          .catch((e) =>
            console.log(`Failed: ${LOST_BUSINESS_NOTIFIER_NOTIFY_PATH}`),
          );
    }
    setProps({
      widgetViewModel: { ...widgetViewModel },
    });
  };
};
