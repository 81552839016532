import { ISettingsGetter } from '@wix/yoshi-flow-editor/tpa-settings';
import { Experiments } from '@wix/yoshi-flow-editor';

import settingsParams from '../../components/BookOnline/settingsParams';
import { ServiceListLayoutOptions } from '../../types/types';

export function getServiceListLayout({
  settings,
  experiments,
  isMobile,
}: {
  settings: {
    get: ISettingsGetter;
  };
  experiments: Experiments;
  isMobile: boolean;
}): ServiceListLayoutOptions {
  return isMobile &&
    !experiments.enabled('specs.bookings.StripLayoutImprovements')
    ? ServiceListLayoutOptions.GRID
    : settings.get(settingsParams.serviceListLayout);
}
