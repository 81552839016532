import { ViewMode } from '../../types/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { IControllerConfig } from '@wix/yoshi-flow-editor';
import {
  BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID,
  BOOKINGS_MAIN_PAGE_PRESET_ID,
} from '../../consts';

export const skipServiceListPageIfNeeded = async (
  viewMode: ViewMode,
  isViewer: boolean,
  wixSdkAdapter: WixOOISDKAdapter,
) => {
  try {
    if (viewMode === ViewMode.PAGE && isViewer) {
      const suffix = await wixSdkAdapter.getBookingsUrlSuffix();
      if (suffix) {
        if (shouldNavigateToCalendarPage(suffix)) {
          await wixSdkAdapter.navigateToBookingsCalendarPage(suffix.slice(1));
        } else {
          await wixSdkAdapter.navigateToBookingsServicePage(suffix.slice(1));
        }
      }
    }
  } catch (e) {}
};

const shouldNavigateToCalendarPage = (suffix: string) =>
  suffix.split('/book').length > 1;

export const onLocationURLChange = (wixCodeApi: any, callback: Function) => {
  const path = wixCodeApi.location.path;
  wixCodeApi.location.onChange((data: { path: string[] }) => {
    if (data.path[0] === path[0]) {
      callback();
    }
  });
};

export const getPresetId = (config: IControllerConfig, isEditorX: boolean) => {
  if (config.publicData.COMPONENT && config.publicData.COMPONENT.presetId) {
    return config.publicData.COMPONENT.presetId;
  } else {
    return isEditorX
      ? BOOKINGS_MAIN_PAGE_EDITOR_X_PRESET_ID
      : BOOKINGS_MAIN_PAGE_PRESET_ID;
  }
};
